<template>
  <div>
    <div class="px-8">
      <div
        v-for="item in usagesList"
        :key="item.id"
        @click="selUsage = item.id"
        class="mb-8 p-16 flex justify-between"
        style="border-radius: 4px;"
        :style="{ border: selUsage === item.id ? '1px solid #0076ff' : '1px solid #e2e2e2' }"
      >
        <div style="width: 80%">
          <div class="font-500">{{ item.title }}</div>
          <div class="mt-8 text-primary text-12">{{ item.desc }}</div>
        </div>
        <img
          v-if="selUsage === item.id"
          style="width: 20px;height: 20px"
          src="https://oss.kuaihuoyun.com/celestia/img/blue-right.png"
        />
      </div>
    </div>

    <div class="mt-24" style="height:0.72rem;">
      <div class="btn-box">
        <van-button size="large" @click="handleToNext" type="primary">下一步</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from '@vue/composition-api';
import TosUploader from '@/components/uploader/uploader.vue';
import { useIdVerifiedStore } from '@/store/idVerify';
import { Button, Toast } from 'vant';
import { useUserStore } from '@/store/user';
export default defineComponent({
  components: {
    TosUploader,
    Toast,
    [Button.name]: Button
  },
  setup() {
    const userVerifiedStore = useIdVerifiedStore();
    const userStore = useUserStore();
    const isReviewOpen = userStore.showReviewKeys;

    const selUsage = ref(1);
    const usagesList = [
      {
        id: 1,
        title: '为司机直系亲属',
        desc: '需提供的材料清单：司机身份证、双方关系证明（户口本、结婚证或其他可证明双方关系的材料）'
      },
      {
        id: 2,
        title: '车老板收款',
        desc:
          '需提供的材料清单：车老板身份证、车辆行驶证、挂靠协议/保单/挂靠费支付凭证/保费支付凭证以及其他可证明车辆所有权的材料'
      }
    ];
    if (!isReviewOpen) {
      usagesList.push({
        id: 3,
        title: '非挂靠车车老板收款',
        desc: '需提供的材料清单：车老板身份证、车辆行驶证、机动车登记证书、其他可证明车辆所有权的材料'
      });
    }

    function handleToNext() {
      // 缓存第一步的数据
      userVerifiedStore.updateInfo({
        usage: selUsage.value,
        step: 1
      });
      userVerifiedStore.saveInfo(); // 同步缓存
    }

    return {
      selUsage,
      usagesList,
      isReviewOpen,
      handleToNext
    };
  }
});
</script>

<style lang="less"></style>
